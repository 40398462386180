import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "react-bootstrap"

const page = {
  name: "Privacy Policy",
  pageDesc:
    "Get in touch to learn more about how Vulcan AI can partner with you for Industry 4.0.",
}
const PrivacyPolicy = _ => {
  return (
    <Layout pageInfo={{ pageName: page.name }} hideTimeline={true}>
      <SEO title={page.name} description={page.pageDesc} />
      <section className="mb-5 " style={{ marginTop: "10vh" }}>
        <Container>
          <h2>TERMS AND CONDITIONS</h2>
          <table width="100%" id="t_n_c_table">
            <tbody>
              {/* <tr>
                <td colspan="3">
                  We, Vulcan AI Pte Ltd (“Vulcan AI”, “We”) are the owners of
                  the website www.vulcan-ai.com(“Website”). We respect data
                  privacy rights and are committed to protecting personal
                  information collected on this Website. This privacy policy
                  (“Privacy Policy”) sets forth how we collect, use and protect
                  the personal information collected on this Website. Please
                  read this Privacy Policy carefully.
                </td>
              </tr>

              <tr>
                <td colspan="3">
                  For residents of the EU, please note that this Privacy Policy
                  has been updated in accordance with the requirements of the EU
                  General Data Protection Regulation (“GDPR”).
                </td>
              </tr>

              <tr>
                <td colspan="3">
                  For Non-EU residents, by continuing to access the Website, you
                  agree to this Privacy Policy. If you do not agree with the
                  terms of this Privacy Policy, you may not access the Website.
                </td>
              </tr> */}

              <tr>
                <td width="40">
                  <strong>1.</strong>
                </td>
                <td width="10"></td>
                <td width="750">
                  <strong>INTRODUCTION</strong>
                </td>
              </tr>
              <tr>
                <td width="40"></td>
                <td width="10"></td>
                <td width="750">
                  We, Vulcan AI Pte Ltd ("Vulcan AI", "We") are the owners of
                  the website www.vulcan-ai.com("Website"), Worksafe for Covid
                  application (“App”), WorkSafe for STF (“App”). We respect data
                  privacy rights and are committed to protecting personal
                  information collected on this Website. This privacy policy
                  ("Privacy Policy") sets forth how we collect, use and protect
                  the personal information collected on this Website.
                </td>
              </tr>
              <tr>
                <td width="40"></td>
                <td width="10"></td>
                <td width="750">
                  Please read this Privacy Policy carefully. By registering your
                  details with us, contacting us directly and purchasing the
                  App, you signify that you have read, understood and agreed to
                  this Privacy Policy. If you do not agree, you should not
                  register or contact us or purchase the App.
                </td>
              </tr>
              <tr>
                <td width="40"></td>
                <td width="10"></td>
                <td width="750">
                  For residents of the EU, please note that this Privacy Policy
                  has been updated in accordance with the requirements of the EU
                  General Data Protection Regulation ("GDPR").
                </td>
              </tr>
              <tr>
                <td>
                  <strong>2.</strong>
                </td>
                <td width="10"></td>
                <td width="750">
                  <strong>WHAT DATA DO WE COLLECT? </strong>
                </td>
              </tr>
              <tr>
                <td></td>
                <td width="10"></td>
                <td width="750">
                  To get in touch with us through the ‘Get Started’ through our
                  Website or when you purchases App or when you purchase the
                  software updates, you are required to provide us with your
                  first name, last name, e-mail address, company's name, IP
                  address for location, phone numbers, company location and
                  postal address, GPS location info (only used by WorkSafe for
                  STF). Any payment, sales and/or invoice/billing information
                  will be collected for Vulcan AI purchase, sales and payment
                  analysis for Vulcan AI business enhancement and product
                  marketing activities. For the sake of brevity, the
                  aforementioned information shall be referred to as "Personal
                  Information". Google Analytics – This Website uses Google
                  Analytics, a web analytics service provided by Google Inc
                  ("Google"). Google Analytics uses cookies, text files that are
                  stored on your computer and enable analysis of your use of the
                  Website. The information generated by the cookies about your
                  use of this website are usually transferred to a server of
                  Google in the US and stored there. In case of activation of IP
                  anonymization on this website your IP address from Google will
                  be truncated within Member States of the European Union or
                  other parties to the Agreement on the European Economic Area.
                  Only in exceptional cases is the full IP address is
                  transmitted to a Google server in the US and shortened there.
                  You can refuse the use of cookies by selecting the appropriate
                  settings on your browser software. You can also prevent the
                  data generated by the cookie and related to your use of the
                  website to Google and the processing of these data by Google,
                  by selecting the in the following link (https (including your
                  IP address.): // Download
                  tools.google.com/dlpage/gaoptout?hl=en) available browser
                  plug-in and install it.
                </td>
              </tr>
              <tr>
                <td></td>
                <td width="10"></td>
                <td width="750">
                  As per the provisions of the GDPR we shall be considered the
                  Controllers of the aforementioned Personal Information.
                </td>
              </tr>
              <tr>
                <td></td>
                <td width="10"></td>
                <td width="750">
                  Please ensure that any Personal Information you share with us
                  is accurate and up to date information. If you are sharing any
                  Personal Information on behalf of a third person, you should
                  ensure that you are authorized to do so.
                </td>
              </tr>
              <tr>
                <td>
                  <strong>3.</strong>
                </td>
                <td width="10"></td>
                <td width="750">
                  <strong>WHAT DO WE DO WITH YOUR DATA? </strong>
                </td>
              </tr>
              <tr>
                <td></td>
                <td width="10"></td>
                <td width="750">
                  We use the Personal Information for the following purposes:
                  <ul>
                    <li>
                      to inform you about our Offerings and to respond to your
                      requests
                    </li>
                    <li>
                      for creation or development of business intelligence or
                      data analytics in relation to the Offerings provided by us
                      (for this purpose we may share the Personal Information
                      with certain software or tools available online)
                    </li>
                    <li>to improve the Offerings</li>
                    <li>to maintain and manage our website</li>
                    <li>to manage our relationship with you</li>
                    <li>for internal record keeping and </li>
                    <li>to comply with our legal or statutory obligations.</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td></td>
                <td width="10"></td>
                <td width="750">
                  Legal Basis (for people from the EU): We will not process your
                  Personal Information without a lawful basis to do so. We will
                  process your Personal Information only to the extent allowed
                  under Article 6(1) of the GDPR.
                </td>
              </tr>
              <tr>
                <td>
                  <strong>4.</strong>
                </td>
                <td width="10"></td>
                <td width="750">
                  <strong>WHO DO WE DISCLOSE YOUR DATA TO?</strong>
                </td>
              </tr>
              <tr>
                <td></td>
                <td width="10"></td>
                <td width="750">
                  We do not sell, rent, share, distribute, lease or otherwise
                  provide your Personal Information to third parties, without
                  your prior consent. Keeping this in mind, we may disclose your
                  Personal Information in the following cases:
                  <ul>
                    <li>
                      Affiliates: We may provide your Personal Information to
                      our affiliates to enable them to improve the Offerings,
                      provide feedback and respond to their queries.
                    </li>
                    <li>
                      Service Providers: We may share your Personal Information
                      with the service providers who work with us in connection
                      with operating the Website and/ or providing the
                      Offerings. All such service providers are subject to
                      stringent confidentiality restrictions consistent with
                      this Privacy Policy.
                    </li>
                    <li>
                      Merger or Acquisition: We may transfer your Personal
                      Information if we are acquired by another entity, or if we
                      merge with another company or transfer a part of our
                      business, including the Website, to a third party. Any
                      such third party or resultant entity that receives your
                      Personal Information shall have the right to continue to
                      use your Personal Information in line with the purposes
                      set out herein. In the event of such a sale or transfer,
                      we may notify you.
                    </li>
                    <li>
                      Legal and Regulatory Authorities: We may disclose your
                      Personal Information in order to comply with our legal
                      obligations/ court orders/ requests by government or
                      regulatory authorities.
                    </li>
                  </ul>
                </td>
              </tr>

              <tr>
                <td>
                  <strong>5.</strong>
                </td>
                <td width="10"></td>
                <td width="750">
                  <strong>
                    TRANSFER OF YOUR PERSONAL INFORMATION ACROSS BORDERS (FOR EU
                    RESIDENTS)
                  </strong>
                </td>
              </tr>
              <tr>
                <td></td>
                <td width="10"></td>
                <td width="750">
                  The Personal Information we collect (of EU residents) might be
                  processed outside the EU at a secure center located in
                  Singapore. We collect and transfer Personal Information
                  outside the EU in accordance with EU data protection laws. We
                  will ensure that any such transfers of Personal Information
                  (outside the EU) will be in accordance with the GDPR. If you
                  have questions, please contact{" "}
                  <a href="mailto:info@vulcan-ai.com">info@vulcan-ai.com</a>.
                </td>
              </tr>

              <tr>
                <td>
                  <strong>6.</strong>
                </td>
                <td width="10"></td>
                <td width="750">
                  <strong>HOW LONG DO WE RETAIN YOUR DATA?</strong>
                </td>
              </tr>
              <tr>
                <td></td>
                <td width="10"></td>
                <td width="750">
                  We will retain your Personal Information as long as it is
                  required to be retained for the purpose of provision of the
                  Offerings and to comply with our legal obligations. Subject to
                  this section, we will try to delete your Personal Information
                  upon reasonable written request for the same. Please note,
                  however, that there might be latency in deleting Personal
                  Information from our servers. For more information on where
                  and how long your personal data is stored, and for more
                  information on your rights of erasure and portability, please
                  contact{" "}
                  <a href="mailto:info@vulcan-ai.com">info@vulcan-ai.com</a>.
                </td>
              </tr>

              <tr>
                <td>
                  <strong>7.</strong>
                </td>
                <td width="10"></td>
                <td width="750">
                  <strong>OUR SECURITY MEASURES</strong>
                </td>
              </tr>
              <tr>
                <td></td>
                <td width="10"></td>
                <td width="750">
                  Your Personal Information is stored on our computer systems
                  located in Singapore, Google email client and our enterprise
                  email client. Although we provide appropriate firewalls and
                  protections, we cannot warrant the security of any Personal
                  Information transmitted as these systems are not hack proof.
                  Data pilferage due to unauthorized hacking, virus attacks,
                  technical issues is possible, and we assume no liability or
                  responsibility for it. You are required to be careful to avoid
                  "phishing" scams, where someone may send you an e-mail that
                  looks like it is from us asking for your personal information.
                </td>
              </tr>

              <tr>
                <td>
                  <strong>8.</strong>
                </td>
                <td width="10"></td>
                <td width="750">
                  <strong>YOUR RIGHTS</strong>
                </td>
              </tr>
              <tr>
                <td></td>
                <td width="10"></td>
                <td width="750">
                  You have the right to access your Personal Information in our
                  possession, right to have us rectify or modify any such
                  Personal Information, right to have us erase/delete your
                  Personal Information, right to restrict us from processing
                  such Personal Information, right to object to our use of your
                  Personal Information. If you would like to exercise ANY of
                  these rights, please contact{" "}
                  <a href="mailto:info@vulcan-ai.com">info@vulcan-ai.com</a>. If
                  you are a resident of the EU, you have the right to lodge a
                  complaint with a data protection authority.
                </td>
              </tr>

              <tr>
                <td>
                  <strong>9.</strong>
                </td>
                <td width="10"></td>
                <td width="750">
                  <strong>LINKS TO OTHER WEBSITES</strong>
                </td>
              </tr>
              <tr>
                <td></td>
                <td width="10"></td>
                <td width="750">
                  Our Website may contain links to other websites/applications
                  of your interest. Please note that we do not have any control
                  over such other websites/applications, and you will be
                  accessing these websites/applications at your own risk.
                  Therefore, we cannot be responsible for the protection and
                  privacy of any information which you provide whilst visiting
                  such websites/applications and those are not governed by this
                  Privacy Policy. You should exercise caution and look at the
                  privacy policy applicable to such websites/applications.
                </td>
              </tr>

              <tr>
                <td>
                  <strong>10.</strong>
                </td>
                <td width="10"></td>
                <td width="750">
                  <strong>LIMITATION OF LIABILITY</strong>
                </td>
              </tr>
              <tr>
                <td></td>
                <td width="10"></td>
                <td width="750">
                  To the extent permissible under the law, we shall not be
                  liable for any direct, indirect, incidental, special,
                  consequential or exemplary damages, including but not limited
                  to, damages for loss of profits, goodwill, data, information,
                  or other intangible losses (even if we have been advised of
                  the possibility of such damages), arising out of this Privacy
                  Policy.
                </td>
              </tr>
              <tr>
                <td>
                  <strong>11.</strong>
                </td>
                <td width="10"></td>
                <td width="750">
                  <strong>GOVERNING LAWS AND DISPUTES</strong>
                </td>
              </tr>
              <tr>
                <td></td>
                <td width="10"></td>
                <td width="750">
                  This Privacy Policy shall be construed and governed by the
                  laws of Singapore without regard to principles of conflict of
                  laws. Any dispute arising out of in connection with this
                  Privacy Policy, including any question regarding its validity,
                  shall be referred to and finally resolved by arbitration
                  administered by the Singapore International Arbitration Centre
                  (“SIAC”) in accordance with the Arbitration Rules of the
                  Singapore International Arbitration Centre (“SIAC Rules”) for
                  the time being in force, which rules are deemed to be
                  incorporate by reference to this clause. The seat of the
                  arbitration shall be Singapore. The Tribunal shall consist of
                  one (1) arbitrator. The language of the arbitration shall be
                  English.
                </td>
              </tr>
              <tr>
                <td></td>
                <td width="10"></td>
                <td width="750">
                  For any EU residents, this Privacy Policy shall be governed by
                  the provisions of the GDPR.
                </td>
              </tr>
              <tr>
                <td>
                  <strong>12.</strong>
                </td>
                <td width="10"></td>
                <td width="750">
                  <strong>CHANGES TO THIS POLICY</strong>
                </td>
              </tr>
              <tr>
                <td></td>
                <td width="10"></td>
                <td width="750">
                  We may update this Privacy Policy from time to time and you
                  are encouraged to check this Privacy Policy on a regular basis
                  to be aware of the changes made to it. This Privacy Policy was
                  last modified on November 2nd, 2020.
                </td>
              </tr>
              <tr>
                <td>
                  <strong>13.</strong>
                </td>
                <td width="10"></td>
                <td width="750">
                  <strong>CONTACT US</strong>
                </td>
              </tr>
              <tr>
                <td></td>
                <td width="10"></td>
                <td width="750">
                  If you have any questions or concerns or grievances regarding
                  this Privacy Policy, you can email us at our grievance
                  email-address{" "}
                  <a href="mailto:info@vulcan-ai.com">info@vulcan-ai.com</a>.
                </td>
              </tr>
            </tbody>
          </table>
        </Container>{" "}
      </section>
    </Layout>
  )
}
export default PrivacyPolicy
